import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  DropdownButton,
  Dropdown,
  Nav,
  Navbar,
  NavDropdown,
} from 'react-bootstrap'
import { Pane } from 'evergreen-ui'

import {
  getGlobalUserId,
  getGlobalUserName,
  getGlobalUserEmail,
} from '../../api/aaa'

import { useSelector } from 'react-redux'

import styles from './Header.module.css'

import GisualLogo from '../../assets/logo.png'
import HelpIcon from '../../assets/help-icon.png'

const HeaderComponent = () => {
  const [width, setWidth] = useState(window.innerWidth)

  const isAuth = useSelector((state) => state.auth.isAuthenticated)
  const userid = getGlobalUserId()
  const name = getGlobalUserName()
  const email = getGlobalUserEmail()
  const { t } = useTranslation('common')

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (width >= 992) {
    return (
      <header className="toolbar">
        <Link to="/">
          <img className={styles.logo} src={GisualLogo} alt="GisualLogo" />
        </Link>
        {isAuth && userid ? (
          <ul className={styles.authDropdown}>
            <li>
              <a
                href="https://gisual.zendesk.com"
                className={styles.helpLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Pane
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={5}
                >
                  <img
                    alt={t('help')}
                    src={HelpIcon}
                    className={styles.helpLinkIcon}
                  />
                  <span>{t('help')}</span>
                </Pane>
              </a>
            </li>
            <div className={styles.verticalSeparator}></div>
            <li styles={styles.dropdownButton}>
              <DropdownButton className={styles.dropdownProfile} title="">
                <p className={styles.profileInfo}>{name}</p>
                <p className={styles.subProfile}>{email}</p>
                <Link className={styles.dropdownLink} to={'/user/'}>
                  <Dropdown.Item className={styles.dropdownItem} as="button">
                    {t('account')}
                  </Dropdown.Item>
                </Link>
                <Link className={styles.dropdownLink} to="/logout">
                  <Dropdown.Item className={styles.dropdownItem} as="button">
                    {t('log_out')}
                  </Dropdown.Item>
                </Link>
              </DropdownButton>
            </li>
          </ul>
        ) : null}
      </header>
    )
  } else {
    return (
      <Navbar fixed="top" variant="dark" expand="lg" className={styles.navbar}>
        <Link to="/">
          <img className={styles.logo} src={GisualLogo} alt="GisualLogo" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className={styles.navbarCollapse}
        >
          <Nav className="mr-auto">
            <Nav.Item>
              <NavLink
                to="/list"
                className={({ isActive }) =>
                  isActive
                    ? `${styles.navLink} ${styles.activeLink}`
                    : styles.navLink
                }
              >
                {t('list_view')}
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/map"
                className={({ isActive }) =>
                  isActive
                    ? `${styles.navLink} ${styles.activeLink}`
                    : styles.navLink
                }
              >
                {t('map_view')}
              </NavLink>
            </Nav.Item>
            <NavDropdown
              title="My Profile"
              id="basic-nav-dropdown"
              className={styles.navLink}
            >
              <NavDropdown.Item disabled className={styles.profileInfoNav}>
                {name}
              </NavDropdown.Item>
              <NavDropdown.Item disabled className={styles.subProfileNav}>
                {email}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                href="https://gisual.zendesk.com"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.navDropdownItem}
              >
                {t('help')}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className={styles.navDropdownItem}>
                <NavLink
                  to={'/user/' + userid}
                  className={styles.navDropdownLink}
                >
                  {t('account')}
                </NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item className={styles.navDropdownItem}>
                <NavLink to={'/logout'} className={styles.navDropdownLink}>
                  {t('log_out')}
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}

export default HeaderComponent
